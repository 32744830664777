<template>
    <div class="statistic" :id="id" data-type="background" data-speed="4">
        <div class="statistic__channel__statistics">
            <div class="statistic__channel__subscriptions">
                <div>
                    <h1 class="iCountUp" v-if="subscriberCount" ref="as">
                        <i-count-up
                                @ready="(instance, CountUp) => readyCounter(instance, CountUp)"
                                :delay="500"
                                :endVal="subscriberCount"
                                :options="counterUpOption"
                        />
                    </h1>
                    <p>Subscribers</p>
                </div>
                <i class="fas fa-users"></i>
            </div>

            <div class="statistic__channel__videos">
                <div>
                    <h1 class="iCountUp" v-if="videoCount">
                        <i-count-up @ready="(instance, CountUp) => readyCounter(instance, CountUp)" :delay="500" :endVal="videoCount" :options="counterUpOption"/>
                    </h1>
                    <p>Videos</p>
                </div>
                <i class="fas fa-video"></i>
            </div>

            <div class="statistic__channel__views">
                <div>
                    <h1 class="iCountUp" v-if="viewCount">
                        <i-count-up @ready="(instance, CountUp) => readyCounter(instance, CountUp)" :delay="500" :endVal="viewCount" :options="counterUpOption"/>
                    </h1>
                    <p>Views</p>
                </div>
                <i class="fas fa-eye"></i>
            </div>
        </div>
    </div>
</template>

<script>
  import ICountUp from 'vue-countup-v2'

  export default {
    name: 'YoutubeStatistic',
    components: { ICountUp },
    mounted () {
      this.id = this._uid
      let that = this
    },
    methods: {
      onWaypoint ({ going, direction }) {
        if (going === this.$waypointMap.GOING_OUT) {
          this.instances.forEach(instance => {
            instance.reset()
          })
        }
        if (going === this.$waypointMap.GOING_IN) {
          this.instances.forEach(instance => {
            instance.start()
          })
        }
      },
      readyCounter(instance, CountUp){
        this.instances.push(instance)
      }
    },
    data () {
      return {
        id: null,
        counterUpOption: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          formattingFn: n => {
            var ranges = [
              {
                divider: 1000000,
                suffix: 'M'
              },
              {
                divider: 1000,
                suffix: 'k'
              }
            ]

            for (var i = 0; i < ranges.length; i++) {
              if (n >= ranges[i].divider) {
                return (n / ranges[i].divider).toString() + ranges[i].suffix
              }
            }
            return n.toString()
          }
        },
        instances: []
      }
    },
    computed: {
      subscriberCount () {
        return Number.parseInt(this.$store.getters.CHANNEL ? this.$store.getters.CHANNEL.sub_count : '0')
      },
      videoCount () {
        return Number.parseInt(this.$store.getters.CHANNEL ? this.$store.getters.CHANNEL.video_count : '0')
      },
      viewCount () {
        return Number.parseInt(this.$store.getters.CHANNEL ? this.$store.getters.CHANNEL.video_count : '0')
      }
    }
  }
</script>

<style lang="scss" scoped>
    .statistic {
        width: 100%;
        position: relative;
        background-image: url("/img/flrow.jpg");
        background-size: cover;

        &__channel__statistics {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 4rem 0 0 0;
            margin-bottom: 1rem;
            justify-content: space-between;
            justify-items: center;
            align-items: center;

            div {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: center;
                justify-items: center;

                div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0 2rem 2rem;
                    margin-bottom: 2rem;

                    h1 {
                        font-size: 2rem;
                        color: #da9100;
                        margin: 0px;
                    }

                    p {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 26px;
                        text-transform: uppercase;
                        color: #bdbdbd;
                        text-align: center;
                        margin: 0;
                    }
                }

                svg {
                    margin: auto;
                    font-size: 4.5rem;
                }
            }
        }
    }

    //   'lg': '1024px',
    @media (min-width: 1024px) {
        .statistic {
            display: flex;
            justify-content: center;

            &__background {
                height: 33vh;
            }

            &__channel__statistics {
                flex-direction: row;
                margin: 0px;
                width: 85%;
                will-change: left;
                left: -20px;
                padding: 3rem;

                div {
                    flex-direction: row;

                    div {
                        padding: 2rem;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
</style>
