<template>
    <div id="app">
        <nav-bar/>
        <router-view/>
        <Footer id="footer"/>
        <a href="#" class="back-to-top" style="display: none;">
            <i class="fas fa-chevron-up"></i>
        </a>
    </div>
</template>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');

    #footer {
        margin-top: 5rem;
    }

    .back-to-top {
        position: fixed;
        right: 10px;
        bottom: 10px;
        font-size: 1.8rem;
        z-index: 999999;
        padding: 0.4rem 0.75rem;
        border-radius: 5px;
        color: #FFFFFF;
        background-color: #da9100;
        text-decoration: none;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    ::-webkit-scrollbar-track {
        margin: 0;
        padding: 0;
        background: #eee;
        border: thin solid lightgray;
        box-shadow: 0px 0px 3px #dfdfdf inset;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #da9100;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #7d7d7d;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    html, body {
        font-family: 'Barlow', sans-serif;
        margin: 0px;
        padding: 0px;
        overflow-x: hidden;

        p {
            line-height: 1.5;
        }
    }

    .thumbnail {
        width: 100%;
        position: relative;
        cursor: default;

        .close-float-video {
            padding: .4rem;
            width: .5rem;
            margin-left: auto;
            margin-right: 5px;
            font-size: 1rem;
            display: none;

            svg {
                cursor: pointer;
            }
        }

        .close-float-video--active {
            display: block;
        }

        .close-float-video:hover {
            color: #da9100;
        }
    }

    .float-player {
        position: fixed !important;
        left: auto;
        right: 0;
        bottom: -4px;
        z-index: 10;

        .embed-container iframe,
        .embed-container object,
        .embed-container embed {
            top: 30px;
        }
    }


    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 1200px) {
        .float-player {
            width: 360px !important;
            height: auto !important;
            bottom: 1rem;
            right: 1rem;

            iframe {
                width: 360px;
                height: 150px;
            }
        }

        .thumbnail {
            height: 0px;
        }
    }
</style>
<script>
  import NavBar from './components/comm/NavBar'
  import Footer from './components/comm/Footer'
  import { mapState } from 'vuex'

  export default {
    components: {
      Footer,
      NavBar
    },
    mounted () {
      window.$(window).on('scroll', function () {

        if (window.$(this).scrollTop() > 250) {
          window.$('.back-to-top').fadeIn(200)
        } else {
          window.$('.back-to-top').fadeOut(200)
        }
      })

      window.$('.back-to-top').on('click', function () {
        window.$('html, body').animate(
          {
            scrollTop: 0
          },
          'slow'
        )
        return false
      })

    },
  }
</script>
