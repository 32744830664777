<template>
    <footer class="footer">
        <ul class="footer__nav">
            <li class="footer__navItem">
                <router-link to="/home">Home</router-link>
            </li>
            <li class="footer__navItem">
                <router-link to="/about">About us</router-link>
            </li>
            <li class="footer__navItem">
                <router-link to="/events">Events</router-link>
            </li>
            <li class="footer__navItem logo">
                <router-link to="/home">
                    <img src="../../assets/logo.png" alt="logo">
                </router-link>
            </li>
            <li class="footer__navItem">
                <router-link to="/gallery">Gallery</router-link>
            </li>
            <li class="footer__navItem">
                <router-link to="/download">Downloads</router-link>
            </li>
            <li class="footer__navItem">
                <router-link to="/contact">Contact us</router-link>
            </li>
        </ul>

        <div class="footer__social">
            <a :href="$store.state.contact[0].fb" target="_blank"><i class="fab fa-facebook-f"></i></a>
            <a :href="$store.state.contact[0].yt" target="_blank"><i class="fab fa-youtube"></i></a>
            <a :href="$store.state.contact[0].insta" target="_blank"><i class="fab fa-instagram"></i></a>
            <a :href="$store.state.contact[0].twitter" target="_blank"><i class="fab fa-twitter"></i></a>
        </div>

        <div class="footer__rights">
            <a href="https://www.orenda.lk/contact">
                COPYRIGHT © {{ new Date() | moment("YYYY") }} by <span>Orenda</span> . All Rights Reserved
            </a>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style lang="scss" scoped>

    .logo {
        flex: 1;
        order: -1;
        padding-right: 100%;
        margin-bottom: 1.5rem;

        img {
            margin-top: -15px;
            width: 80px;
            height: auto;
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        align-items: center;
        background-color: hsl(0, 4%, 5%);

        &__nav {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            align-items: start;
            justify-content: start;
            justify-items: start;
            margin-left: 0px;
            width: 100%;
        }

        &__navItem {
            width: auto;
            text-align: left;
            margin: .5rem 1rem;

            a {
                color: hsl(240, 0%, 50%);
                text-decoration: none;
                transition-timing-function: ease-in-out;
                transition-property: color;
                transition-delay: 100ms;
            }

            a:hover {
                color: hsl(40, 100%, 43%);
            }
        }

        &__social {
            display: flex;
            font-size: 1.2rem;
            justify-content: center;
            justify-items: center;

            margin: 1rem 0;

            a:nth-child(1) {
                background-color: #3b5998;

                svg {
                    left: calc(50% - .4rem);
                    top: calc(50% - .5rem);
                }
            }

            a:nth-child(2) {
                background-color: #c00;
                svg {
                    left: calc(50% - .65rem);
                }
            }

            a:nth-child(3) {
                background-color: #bc2a8d;
                svg {
                    top: calc(50% - .59rem);
                }
            }

            a:nth-child(4) {
                background-color: #1DA1F2;

                svg {
                    left: calc(50% - .6rem);
                    top: calc(50% - .6rem);
                }
            }

            a {
                margin: 0px .5rem;
                padding: 1.3rem;
                border-radius: 50%;
                position: relative;

                svg {
                    position: absolute;
                    left: calc(50% - .5rem);
                    top: calc(50% - .5rem);
                    color: white;
                }
            }

            a:hover {
                background-color: hsl(40, 100%, 43%);

                svg {

                }
            }
        }

        &__rights {
            margin-top: 1rem;
            padding: 1.3rem 0;
            background-color: #212121;
            width: 100%;
            display: flex;
            justify-content: center;
            text-transform: uppercase;

            a {
                font-size: .8rem;
                font-weight: bold;
                text-decoration: none;
                color: #BDBDBD;
                padding-left: 1rem;
                padding-right: 1rem;

                span {
                    color: hsl(40, 100%, 43%)
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .logo {
            order: 0;
            padding-right: 0px;
        }

        .footer {
            .footer__nav {
                margin-left: 0px;
                width: unset;
            }
        }
    }
</style>
