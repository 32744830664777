<template>
  <div class="latestVideos">
    <div
      class="video"
      v-for="video in latestVideos"
      :key="video.id.videoId"
      v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
    >
      <!--<div class="thumbnail">
                <a class="close-float-video"
                   @click="() => $store.dispatch('CLOSE_CURRENTLY_FLOATED_VIDEO').then(() => $store.state.currentlyPlaying.pauseVideo())">
                    <i class="far fa-times-circle"></i>
                </a>
                <youtube
                        :id="video.id.videoId"
                        :title="video.snippet.title"
                        :video-id="video.id.videoId"
                        :ref="video.id.videoId"
                        @playing="() => $store.dispatch('NOW_PLAYING', $refs[video.id.videoId][0].player)"
                ></youtube>
            </div>
            <div class="body">
                <span class="published-at">{{ video.snippet.publishedAt | moment("DD MMMM YYYY") }}</span>
                <h2 class="title">{{ video.snippet.title }}</h2>
                <p class="text">{{ video.snippet.description }}</p>
      </div>-->
      <card
        :videoId="video._id.videoId"
        :title="video.snippet.title"
        :publishedAt="video.snippet.publishedAt"
        :videoDescription="video.snippet.description"
        :thumbnails="video.snippet.thumbnails"
      />
    </div>
  </div>
</template>

<script>
import Card from "../gallery/Card";
export default {
  name: "LatestVideos",
  components: { Card },
  computed: {
    latestVideos() {
      return this.$store.getters.LATEST_VIDEOS.slice(1, 4);
    }
  },
  data: () => ({
    intersectionOptions: {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      threshold: [0, 1]
    }
  }),
  methods: {
    onWaypoint({ going, direction }) {
      if (going === this.$waypointMap.GOING_OUT) {
        this.$store.dispatch("DO_FLOAT_VIDEO");
      }
      if (going === this.$waypointMap.GOING_IN) {
        this.$store.dispatch("CLOSE_CURRENTLY_FLOATED_VIDEO");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.latestVideos {
  margin-top: 3rem;
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  cursor: pointer;

  .video {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border-radius: 5px;

    .body {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .published-at {
        padding: 1rem 1.5rem;
        font-family: "Barlow", sans-serif;
        font-size: 1rem;
        color: #ffffff;
        background-color: #da9100;
        border-radius: 5px;
        margin-right: auto;
      }

      .title {
        font-size: 1.2rem;
        font-weight: 100;
        color: #da9100;
        text-align: left;
      }

      .text {
        font-size: 1rem;
        font-family: "Barlow", sans-serif;
        text-align: justify;
        margin-top: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
