<template>
    <div class="scard">
        <div class="scard__thumbnail embed-container">
            <a class="close-float-video"
               @click="() => $store.dispatch('CLOSE_CURRENTLY_FLOATED_VIDEO').then(() => $store.state.currentlyPlaying.pauseVideo())">
                <i class="far fa-times-circle"></i>
            </a>
            <div class="container" :style="`background-image: url(${thumbnail()});`" v-if="isLoading" @click="playVideo">
                <div class="wrapper">
                    <div class="tri"></div>
                </div>
            </div>
            <youtube
                    v-else
                    :video-id="videoId"
                    :ref="videoId"
                    @ready="readyPlayer"
                    @playing="playing"
                    @paused="paused"
            ></youtube>
        </div>
        <div class="scard__body">
            <div class="scard__title">
                <h2>Sound Of Jesus</h2>
            </div>
            <div class="scard__description">
                <p>{{desc}}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SoundOfJesus",
        props: {
            desc: String,
            videoId: String
        },
        data() {
            return {
                isLoading: true,
            }
        },
        methods: {
            thumbnail(){
                return `https://img.youtube.com/vi/${this.videoId}/sddefault.jpg`;
            },
            playVideo() {
                this.isLoading = false;
                this.$emit("playing")
            },
            readyPlayer() {
                this.$refs[this.videoId].player.playVideo()
            },
            playing(){
                this.$store.dispatch('NOW_PLAYING', this.$refs[this.videoId].player);
                this.$emit("playing")
            },
            paused(){
                this.$emit("paused")
            }
        }
    }
</script>

<style lang="scss">
    @import "~@/assets/scss/_variables";
    .scard{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        &__thumbnail{
            width: 100%;
            position: relative;
            cursor: default;

            .close-float-video {
                padding: .4rem;
                width: .5rem;
                margin-left: auto;
                margin-right: 5px;
                font-size: 1rem;
                display: none;

                svg {
                    cursor: pointer;
                }
            }

            .close-float-video--active {
                display: block;
            }

            .close-float-video:hover {
                color: #da9100;
            }

            .container {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: calc(100% - 0px);
                background-repeat: no-repeat;
                background-position: center;
                transform-style: preserve-3d;
                cursor: pointer;
            }

            .wrapper {
                background: rgba(0, 0, 0, 0.55);
                width: 50px;
                height: 24px;
                border-radius: 5px;
                padding-top: 10px;
                position: relative;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
            }

            .container:hover .wrapper {
                background: #cd201f;
            }

            .tri {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 14px;
                border-color: transparent transparent transparent #ffffff;
                margin: 0 auto;
            }
        }

        &__body{
            margin: 0px;
            padding: 1rem;
        }

        &__title h2{
            width: 100%;
            text-align: left;
            font-size: 2.2rem;
            color: $primary;
            text-decoration: none;
            font-weight: bold;
        }

        &__description p{
            width: 100%;
            text-align: justify;
            font-size: 1rem;
        }
    }

    @media(min-width: 768px){
        .scard{
            flex-direction: row;
            &__thumbnail{
                max-width: 50%;
                height: 45px;
                padding-bottom: 23.25% !important;
                margin-right: 2rem;
            }
            &__body{
                margin: 0px;
                max-width: 50%;
                /*padding: 1rem 1rem 1rem 5rem;*/
            }
        }
    }
</style>
