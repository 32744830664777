import firebase from 'firebase'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBd3kLz8dSQ8KaXtwQ8XKG--Hy-doCF18g',
  authDomain: 'soj-backend.firebaseapp.com',
  databaseURL: 'https://soj-backend.firebaseio.com',
  projectId: 'soj-backend',
  storageBucket: 'soj-backend.appspot.com',
  messagingSenderId: '399650828185',
  appId: '1:399650828185:web:fa56d8c0cc8e986467ad08',
  measurementId: 'G-33CTVEMZN1'
}



// var firebaseConfig = {
//     apiKey: "AIzaSyBTLYck767ol3hcMV_AkshJCqyjwNgEdzE",
//     authDomain: "soj-bacup.firebaseapp.com",
//     databaseURL: "https://soj-bacup.firebaseio.com",
//     projectId: "soj-bacup",
//     storageBucket: "soj-bacup.appspot.com",
//     messagingSenderId: "390475357119",
//     appId: "1:390475357119:web:6c7d07d5046615824adf0a"
//   };

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export default firebase
