<template>
  <header>
    <div class="top-bar">
      <div class="social">
        <a :href="$store.state.contact[0].fb" target="_blank">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a :href="$store.state.contact[0].insta" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a :href="$store.state.contact[0].twitter" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
        <a :href="$store.state.contact[0].yt" target="_blank">
          <i class="fab fa-youtube"></i>
        </a>
      </div>
      <div class="contacts">
        <a :href="`mailto:${$store.state.contact[0].email}`" target="_blank">
          <i class="fas fa-at"></i> {{ $store.state.contact[0].email }}
        </a>
        <a :href="`tel:${$store.state.contact[0].tel}`" target="_blank">
          <i class="fas fa-phone"></i> {{ $store.state.contact[0].tel }}
        </a>
      </div>
    </div>
    <nav id="nav-bar" class="navigation">
      <div class="navigation__mobile">
        <div class="navigation__logo-container">
          <router-link to="/">
            <img src="../../assets/logo.png" alt="logo" />
          </router-link>
        </div>

        <div class="navigation__menu">
          <button class="navigation__button" @click="natigationToggle">
            <i class="fas fa-bars text-orenda"></i>
          </button>
        </div>
      </div>

      <div class="navigation__content">
        <ul class="navigation__left">
          <li class="navigation__item">
            <router-link to="/home">
              <i class="fas fa-home"></i>Home
            </router-link>
          </li>
          <li class="navigation__item">
            <router-link to="/about">
              <i class="fas fa-address-card"></i>About
            </router-link>
          </li>
          <li class="navigation__item">
            <router-link to="/events">
              <i class="fas fa-calendar-week"></i>Events
            </router-link>
          </li>
          <li class="navigation__item">
            <router-link to="/gallery">
              <i class="fas fa-video"></i>Gallery
            </router-link>
          </li>
          <li class="navigation__item">
            <router-link to="/downloads">
              <i class="fas fa-download"></i>Downloads
            </router-link>
          </li>
          <li class="navigation__item">
            <router-link to="/contact">
              <i class="fas fa-phone"></i>Contact us
            </router-link>
          </li>
        </ul>

        <ul class="navigation__right">
          <li class="navigation__item--big">
            <a @click="showLive">
              <i class="fab fa-youtube"></i>Watch Live
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <SweetModal ref="liveMod">
      <div slot="title" class="live">
        <h1 class="live__title">
          <i class="fab fa-youtube"></i> Live Stream
        </h1>
      </div>
      <div class="embed-container">
        <iframe src="https://www.youtube.com/embed/ubDcmIUMcLo" frameborder="0" allowfullscreen></iframe>
      </div>
    </SweetModal>
  </header>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
export default {
  name: "NavBar",
  components: { SweetModal },
  mounted() {
    // The debounce function receives our function as a parameter
    const debounce = fn => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      };
    };

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    };

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true
    });

    // Update scroll position for first time
    storeScroll();
  },
  watch: {
    $route: "natigationToggle"
  },
  methods: {
    natigationToggle() {
      document
        .querySelector(".navigation__content")
        .classList.toggle("navigation__content--shown");
    },
    showLive() {
      this.$refs.liveMod.open();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.top-bar {
  display: none;
}

.live {
  padding-bottom: 0.5rem;
  &__tilte {
    display: inline-block;
    color: $primary;
    svg {
      margin-right: 0.25rem;
    }
  }
}

html:not([data-scroll="0"]) {
  #nav-bar {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  }
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /*width: 100%;*/
  z-index: 100;
  background-color: white;

  &__mobile {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
  }

  &__logo-container {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    color: white;

    a {
      display: flex;
      align-items: center;

      img {
        height: 40px;
        width: auto;
      }
    }
  }

  &__menu {
    display: block;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: $primary;
    border-color: $primary;
    border-radius: 0.25rem;
    font-size: 1rem;
    background-color: transparent;
  }

  &__content {
    display: none;
    flex-direction: column;
    width: auto;
    background-color: white;
    padding-top: 1.5rem;
    padding-bottom: 0.75rem;

    &--shown {
      display: flex;
    }
  }

  &__left,
  &__right {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none;

    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__item {
    padding: 0.75rem 1rem;

    a {
      color: $primary;
      text-decoration: none;

      svg {
        margin-right: 0.25rem;
      }
    }

    &--big {
      padding-top: 0.7rem;
      cursor: pointer;
      transition-property: all;
      transition-timing-function: ease-in-out;
      transition-duration: 100ms;

      a {
        padding: 0.75rem 1.3rem;
        color: #ffffff;
        background-color: $primary;
        border-radius: 2rem;
        text-decoration: none;

        svg {
          margin-right: 0.25rem;
        }
      }
    }

    &--big:hover {
      transform: scale(1.05);
    }
  }
}

/*'md': '768px',*/
@media (min-width: 768px) {
  .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0.5rem;
    background-color: #110f0f;

    a {
      margin: 0px 6px;
      color: $primary;
      text-decoration: none;
    }

    .contacts{
      font-size: .8rem;
    }

    a:hover {
      color: #ffc107;
    }
  }
  .navigation {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    &__menu {
      display: none;
    }

    &__mobile {
      flex: 0;
    }

    &__logo-container {
      margin-left: 3rem;
      margin-right: 1rem;
    }

    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      flex: 3;
      padding: 0px 2rem;
      max-height: 100%;
    }

    &__left{
      margin: 0px;
    }

    &__left,
    &__right {
      flex-direction: row;
      li {
        display: flex;
        align-items: stretch;
        position: relative;
        a {
          font-family: "Barlow", sans-serif;
          text-transform: uppercase;
          display: flex;
          align-items: center;
        }
      }
    }

    &__left {
      align-items: stretch;
      li {
        a {
          padding: 0 1.5rem;
          text-align: center;
        }
        .router-link-active {
          color: #ffffff;
          background-color: $primary;
          height: 100%;
          width: 100%;
        }
      }

      li:not(:first-child) {
        a {
          svg {
            display: none;
          }
        }
      }
    }

    &__right{
      li a{
        margin-top: -4px;
      }
    }

    &__item {
      margin: 0 0.5rem;
      padding: 0px;
    }
  }
}
</style>
