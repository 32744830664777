<template>
    <div class="deco">
        <div class="deco__item">
            <a @click.prevent="showLive('embed/ubDcmIUMcLo', 'Watch Live')">
                <h1>Watch</h1>
                <h1>Live</h1>
            </a>
        </div>
        <div class="deco__item">
            <a  @click.prevent="showLive('embed/videoseries?list=PLcQPHrRcAP8JPba6rnoH85PDxv2Mmgdhv', 'Daily Reflection')">
                <h1>Daily</h1>
                <h1>Reflection</h1>
            </a>
        </div>
        <div class="deco__item">
            <a  @click.prevent="showLive('embed/videoseries?list=PLcQPHrRcAP8JtOsB-bBKgZ6tdZ7zo59oe', 'Bible Studies')">
                <h1>Bible</h1>
                <h1>Studies</h1>
            </a>
        </div>
        <div class="deco__item">
            <a  @click.prevent="showLive('embed/videoseries?list=PLcQPHrRcAP8LO_opPSHAjVb6-xHUdZ6jU', 'Special Programs')">
                <h1>Special</h1>
                <h1>Programs</h1>
            </a>
        </div>
        <SweetModal ref="liveMod">
            <div slot="title" class="live">
                <h1 class="live__title">
                    <i class="fab fa-youtube"></i> {{ selected.title}}
                </h1>
            </div>
            <div class="embed-container">
                <iframe :src="`https://www.youtube.com/${selected.part}`" frameborder="0" allowfullscreen></iframe>
            </div>
        </SweetModal>
    </div>
</template>

<script>
    import {SweetModal} from "sweet-modal-vue";

    export default {
        name: "CarouselDecorator",
        components: {SweetModal},
        data() {
            return {
                selected: {
                    title: "",
                    part: ""
                }
            }
        },
        methods: {
            showLive(part, title) {
                this.selected = {
                    part: part,
                    title:title
                };
                this.$refs.liveMod.open();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .deco {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 1rem;
        width: 92%;

        &__item {
            cursor: pointer;
            border-radius: 20px 0px 20px 0px;
            position: relative;
            display: flex;
            padding: 30px;
            background-color: #da9100;
            color: #FFFFFF;
            font-size: 1rem;
            margin-bottom: 1rem;

            a {
                width: auto;
                height: 65px;
                background-image: url("/img/pc_icon.png");
                background-repeat: no-repeat;
                text-decoration: none;
                padding-left: 80px;

                h1 {
                    color: #FFFFFF;
                    margin: 0;
                    padding: 0;
                }

                h1:first-child {
                    font-size: 2.8rem;
                }

                h1:last-child {
                    font-size: 1.8rem;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .deco {
            flex-direction: row;
            padding: 1rem 2.5rem;
            justify-content: space-between;

            &__item {
                margin-bottom: unset;
            }
        }

    }
</style>
