<template>
    <div>
        <vue-owl-carousel
                :responsive="carouselData"
                dots="true"
                nav="true"
                v-if="state_"
        >
            <div class="event" v-for="event in events" :key="event.title">
                <img v-cache="event.images[0]" :alt="event.title" class="event__image">
                <div class="event__body">
                    <h1>{{ event.title }}</h1>
                    <span>
                <i class="far fa-clock"></i>
                {{ new Date(event.date) | moment("DD MMMM YYYY")}}
            </span>
                    <div style="overflow-y: hidden">
                        <p>
                            {{ event.description }}
                        </p>
                    </div>
                </div>
            </div>
        </vue-owl-carousel>
    </div>
</template>

<script>
    import VueOwlCarousel from "vue-owl-carousel"

    export default {
        name: "CardCarousel",
        components: {VueOwlCarousel},
        props: {
            events: Array
        },
        created() {
            var checkExist = setInterval(function () {
                const nav = document.querySelector('.owl-nav');
                if (nav) {
                    const next = document.querySelector('.owl-next');
                    const prev = document.querySelector('.owl-prev');

                    prev.innerHTML = '<i class="fas fa-angle-left"></i>';
                    next.innerHTML = '<i class="fas fa-angle-right"></i>';

                    clearInterval(checkExist);
                }
            }, 100);
        },
        mounted(){
            let that = this;
            setTimeout(()=>{
                that.state_ = true
            }, 1000)
        },
        data() {
            return {
                state_: false,
                carouselData: {
                    0: {
                        items: 1,
                        loop: true,
                        margin: 10,
                        nav: false
                    },
                    1024: {
                        items: 3,
                        loop: true,
                        margin: 30
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
        overflow: hidden;
        position: relative;
        line-height: $lineHeight;
        max-height: $lineHeight * $lineCount;
        text-align: justify;
        margin-right: -1em;
        padding-right: 1em;
        &:before {
            content: '...';
            position: absolute;
            right: 0;
            bottom: 0;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 1em;
            height: 1em;
            margin-top: 0.2em;
            background: $bgColor;
        }
    }

    .event {
        position: relative;
        padding: .5rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        height: 5rem;
        background-color: white;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
        margin: .2rem 0;

        &__image {
            max-width: 5rem;
            height: 5rem;
            margin-right: .5rem;
            cursor: pointer;
        }

        &__body {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: start;
            height: 5rem;
            width: 100%;

            h1, p, span {
                margin: 0;
                padding: 0;
                text-align: start;
            }

            h1 {
                width: 100%;
                font-size: 1rem;
            }

            span {
                font-size: 0.6rem;
                color: #BDBDBD;
                margin-top: .1rem;
            }

            div {
                p {
                    width: 90%;
                    font-size: .8rem;
                    margin-top: .2rem;
                    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .event {
            div {
                p {
                    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 3, $bgColor: white);
                }
            }
        }
    }

</style>
