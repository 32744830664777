<template>
    <div class="row">
        <div class="row__inner">
            <div class="tile" v-for="video__ in $store.state.latestVideos" :key="video__.id.videoId"
                 @click="playVideo(video__)">
                <div class="tile__media">
                    <img class="tile__img" :src="thumbnail(video__.snippet.thumbnails)" alt/>
                </div>
                <div class="tile__details">
                    <div class="tile__title">{{ video__.snippet.title }}</div>
                </div>
            </div>
        </div>

        <sweet-modal ref="netflx_modal" :title="selected? selected.snippet.title: '' " @close="()=> this.$store.getters.CURRENTLY_PLAYING.pauseVideo() ">
            <card v-if="selected" :blocking="true" :videoId="selected._id.videoId"
                  :thumbnails="selected.snippet.thumbnails"></card>
        </sweet-modal>
    </div>
</template>

<script>
    import {SweetModal} from "sweet-modal-vue"
    import Card from "../gallery/Card"

    export default {
        name: "LatestVideosNetflix",
        components: {SweetModal, Card},
        data() {
            return {
                selected: ""
            }
        },
        computed: {
            latestVideos() {
                return this.$store.getters.LATEST_VIDEOS.slice(1, 6);
            }
        },
        methods: {
            thumbnail(thumbnails) {
                if (!thumbnails) return;

                if (thumbnails.standard) {
                    return thumbnails.standard.url;
                } else if (thumbnails.high) {
                    return thumbnails.high.url;
                } else if (thumbnails.medium) {
                    return thumbnails.medium.url;
                } else {
                    return thumbnails.default.url;
                }
            },
            playVideo(video) {
                this.selected = video;
                this.$refs.netflx_modal.open()
            }
        }
    };
</script>

<style scoped>
    body,
    html {
        padding: 0 10px;
        margin: 0;
        background: #0e0f11;
        color: #ecf0f1;
        font-family: "Open Sans", sans-serif;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    * {
        box-sizing: border-box;
    }

    h1,
    p {
        text-align: center;
    }

    p {
        width: 100%;
        max-width: 500px;
        margin: auto;
    }

    a:link,
    a:hover,
    a:active,
    a:visited {
        transition: color 150ms;
        color: #95a5a6;
        text-decoration: none;
    }

    a:hover {
        color: #7f8c8d;
        text-decoration: underline;
    }

    .contain {
        width: 100%;
    }

    .row {
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 100%;
    }

    .row__inner {
        transition: 450ms transform;
        font-size: 0;
        white-space: nowrap;
        margin: 38px 0 45px 0;
    }

    .tile {
        position: relative;
        display: inline-block;
        width: 237px;
        height: 140.625px;
        margin-right: 5.5px;
        margin-left: 5.5px;
        font-size: 20px;
        cursor: pointer;
        transition: 450ms all;
        transform-origin: center left;
        color: white;
    }

    .tile__img {
        width: 237px;
        height: 138px;
        object-fit: cover;
    }

    .tile__details {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        font-size: 10px;
        opacity: 0;
        background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.9) 0%,
                rgba(0, 0, 0, 0) 100%
        );
        transition: 450ms opacity;
    }

    .tile__details:after,
    .tile__details:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: #000;
    }

    .tile__details:after {
        margin-top: -25px;
        margin-left: -25px;
        width: 50px;
        height: 50px;
        border: 3px solid #ecf0f1;
        line-height: 50px;
        text-align: center;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    .tile__details:before {
        content: "▶";
        left: 0;
        width: 100%;
        font-size: 30px;
        margin-left: 7px;
        margin-top: -18px;
        text-align: center;
        z-index: 2;
    }

    .tile:hover .tile__details {
        opacity: 1;
    }

    .tile__title {
        position: absolute;
        bottom: 0;
        padding: 10px;
    }

    .row__inner:hover {
        transform: translate3d(-62.5px, 0, 0);
    }

    .row__inner:hover .tile {
        opacity: 0.3;
    }

    .row__inner:hover .tile:hover {
        transform: scale(1.5);
        opacity: 1;
    }

    .tile:hover ~ .tile {
        transform: translate3d(125px, 0, 0);
    }
</style>
