/* global gapi */
import './helpers/firebase'
import * as jQuery from 'jquery'

window['jQuery'] = jQuery

import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import VueWaypoint from 'vue-waypoint'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)
Vue.use(VueWaypoint)
Vue.use(VueYoutube)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'es6-promise/auto'

import App from './App.vue'
import router from './router'
import store from './store/store'

import jquery from 'jquery'

window.$ = jquery
import cache from './custom/cache'
Vue.directive('cache', cache)

new Vue({
  router,
  store,
  created () {
    this.$store.dispatch('persistDatabase')
    this.$store.dispatch('init')
  },
  render: h => h(App)
}).$mount('#app')
