<template>
    <div class="playlist">
        <h1 class="playlist__chanelTitle">{{ $store.state.channel[0].channel_title }}</h1>

        <article class="playlist__desc">
            <p class="playlist__paragraph">{{ $store.state.channel[0].channel_description }}</p>
        </article>

        <div class="playlist__thumbs">
            <div
                    class="playlist__thumb"
                    v-for="playlist in playlists.filter( list => !list.deleted_at)"
                    :key="playlist.id"
                    @click="playPlaylist(playlist)"
            >
                <aside>
                    <img :src="imageUrl(playlist.snippet.thumbnails.default.url)" alt="">

                    <i class="fab fa-youtube"></i>
                </aside>
                <h2>{{ playlist.snippet.title }}</h2>
            </div>
        </div>

        <div class="sub-btn">
            <subscribe-button/>
        </div>

        <playlist-model
                id="home-yputube-playlist-model"
                ref="model"
                :title="thisList.snippet.title"
                :description="thisList.snippet.description"
                :published-at="thisList.snippet.publishedAt"
                :playlist-id="thisList.id"
        />
    </div>
</template>

<script>
    import {SweetModal} from 'sweet-modal-vue'
    import SubscribeButton from "../comm/SubscribeButton";
    import PlaylistModel from "../comm/PlaylistModel";
    import { mapState } from 'vuex'

    export default {
        name: "YoutubePlaylist",
        components: {PlaylistModel, SubscribeButton, SweetModal},
        data() {
            return {
                thisList: {
                    id: "",
                    snippet: {
                        title: "",
                        description: "",
                        publishedAt: "",
                    },
                    player: {
                        embedHtml: ""
                    }
                }
            }
        },
        computed: mapState(['playlists']),
        methods: {
            playPlaylist(playlist) {
                this.thisList = playlist;
                // this.$refs.model.open();
              // const url = `https://www.youtube.com/watch?v=${this.$store.state.playlistItems.filter( item => item.snippet.playlistId === playlist.id)[0].snippet.resourceId.videoId}&list=${playlist.id}`
              window.open(`https://www.youtube.com/playlist?list=${playlist.id}`)
              console.log(url)
              window.open(url, '_blank')
            },
            imageUrl(url){
                return url.replaceAll('http:', 'https:')
            }
        }
    };
</script>

<style lang="scss">
    @import "../../assets/scss/variables";

    .sub-btn {
        margin-top: 2rem;
    }

    .playlist {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0rem;

        &__chanelTitle {
            color: #da9100;
            width: 100%;
            text-align: center;
            position: relative;
            font-size: 2rem;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        &__paragraph {
            color: #347d7a;
            margin-bottom: 3rem;
            margin-top: 0px;
            text-align: justify;
            font-family: 'Barlow', sans-serif;
        }

        &__slogen {
            font-size: 1.3rem;
            text-align: center;
        }

        &__thumbs {
            margin-top: 3rem;
            width: 100%;
            display: grid;
            grid-gap: 2em;
            grid-template-columns: repeat(auto-fit, minmax(6.8rem, 1fr));
        }


        &__thumb {
            cursor: pointer;
            position: relative;

            aside {
                position: relative;
                text-align: center;
            }

            h2 {
                font-size: .8rem;
                text-align: center;
            }

            img {
                width: 100%;
                height: auto;
                opacity: 1;
                transition: .5s ease;
                backface-visibility: hidden;
            }

            svg {
                transition: .5s ease;
                backface-visibility: hidden;
                color: #c00;
                margin: 0px;
                font-size: 1.8rem;
                position: absolute;
                left: calc(50% - 1rem);
                top: calc(50% - 1rem);
                opacity: 0;
            }
        }

        &__thumb:hover {
            img {
                opacity: 0.5;
            }

            svg {
                opacity: 1;
            }
        }

        &__desc {
            display: flex;
            flex-direction: column;
        }

        &__paragraph {
            text-align: justify;
            font-size: 1rem;
            padding: 0;
            margin: .8rem 0 0 0;
        }

        &__chanelSub {
            background-color: #c00;
            color: white;
            padding: .75rem 1rem;
            margin: 1rem auto;
            font-size: 1.2rem;
            border-radius: .5rem;
            cursor: pointer;
        }
    }

    //   'lg': '1024px',
    @media (min-width: 1024px) {
        .playlist {
            &__thumbs {
                width: 70%;
            }
            &__paragraph{
                text-align: center;
            }
        }
    }
</style>
