import { errors } from 'faunadb'

export default {
  // When the bound element is inserted into the DOM...
  bind: (el, binding, vnode) => {

    // IndexedDB
    let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
      IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction,
      dbVersion = 1.0

    // Create/open database
    let request = indexedDB.open('v-cache', dbVersion),
      db,
      createObjectStore = function (dataBase) {
        // Create an objectStore
        dataBase.createObjectStore('images')
      },

      putImageInDb = function (key, blob) {
        // Open a transaction to the database
        let transaction = db.transaction(['images'], 'readwrite')
        // Put the blob into the dabase
        let put = transaction.objectStore('images').put(blob, key)
        // Retrieve the file that was just stored
        transaction.objectStore('images').get(key).onsuccess = function (event) {
          let imgFile = event.target.result
          let reader = new FileReader();
          reader.readAsDataURL(imgFile);
          reader.onloadend = function() {
            let base64data = reader.result;
            el.src = base64data;
          }
        }
      },

      getImageFile = function (url) {
        let transaction = db.transaction(['images'], 'readwrite')
        new Promise((resolve, reject) => {
          let get = transaction.objectStore('images').get(url)
            get.onsuccess = (event) => resolve(get)
            get.onerror = (error) => reject(error)
        })
          .then(get => {
            if( get.result){
              let reader = new FileReader();
              reader.readAsDataURL(get.result);
              reader.onloadend = function() {
                let base64data = reader.result;
                el.src = base64data;
              }
            } else {
              var xhr = new XMLHttpRequest(),
                blob
              xhr.open('GET', url, true)
              // Set the responseType to blob
              xhr.responseType = 'blob'
              xhr.addEventListener('load', function () {
                if (xhr.status === 200) {
                  // Blob as response
                  blob = xhr.response
                  // Put the received blob into IndexedDB
                  putImageInDb(url, blob)
                } else {
                  el.src=""
                }
              }, false)
              // Send XHR
              xhr.send()
              el.src = "/img/img-loading.gif"
            }
          })
          .catch(errors => console.error(errors))
      }

    request.onerror = function (event) {
    }

    request.onsuccess = function (event) {
      db = request.result
      db.onerror = function (event) {
      }
      // Interim solution for Google Chrome to create an objectStore. Will be deprecated
      if (db.setVersion) {
        if (db.version != dbVersion) {
          let setVersion = db.setVersion(dbVersion)
          setVersion.onsuccess = function () {
            createObjectStore(db)
            getImageFile(binding.value)
          }
        } else {
          getImageFile(binding.value)
        }
      } else {
        getImageFile(binding.value)
      }
    }

    // For future use. Currently only in latest Firefox versions
    request.onupgradeneeded = function (event) {
      createObjectStore(event.target.result)
    }

  }
}
