import { Promise } from "core-js";

const axios = require("axios");
const URL = "https://www.googleapis.com/youtube/v3/search";
const API_KEY = "AIzaSyC_y5l5RYXZJNgDso--TJuMmgOYAYUUsJQ";
const CHANNEL_ID = "UCFUC1tsItNJe7gPxcgeydKw";

const mock = [{
    title: "St. Sebastian College Worship",
    date: "2020/03/03",
    thumbnail: "https://drive.google.com/uc?export=view&id=1zxoFoAd4y31IkSv_kyi4ydrxDFiM0U_H",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad distinctio dolore explicabo itaque omnis, praesentium quo sed soluta totam vitae? Aliquam consequuntur distinctio labore laborum molestiae necessitatibus odit quos repudiandae!"
}, {
    title: "Worship - Mahawaththa Church",
    date: "2020/03/08",
    thumbnail: "https://drive.google.com/uc?export=view&id=1IQ36cjc_OHB5LwLp0KhT3sMw-HMty4ET",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad distinctio dolore explicabo itaque omnis, praesentium quo sed soluta totam vitae? Aliquam consequuntur distinctio labore laborum molestiae necessitatibus odit quos repudiandae!"
}, {
    title: "Jesuth ekka poya",
    date: "2020/03/09",
    thumbnail: "https://drive.google.com/uc?export=view&id=1Fg99clQeXOrjlKX6COxr31hslCfasOpy",
    description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad distinctio dolore explicabo itaque omnis, praesentium quo sed soluta totam vitae? Aliquam consequuntur distinctio labore laborum molestiae necessitatibus odit quos repudiandae!"
}, ];


const events = new Promise((resolve, reject) => {
    if (process.env.NODE_ENV === 'production') {
        // axios.get(`${URL}?part=snippet&channelId=${CHANNEL_ID}&maxResults=10&order=date&type=video&key=${API_KEY}`)
        //     .then(resp => resp.data)
        //     .then(resp => {
        //         resolve(resp)
        //     }).catch(error => console.error(error))
    } else {
    }
        resolve(mock)
});

export default events;
