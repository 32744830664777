<template>
    <vue-owl-carousel
            id="main-carousel"
            :loop="true"
            :items="1"
            :nav="false"
            autoplay
            class="carousel"
    >
        <div class="carousel__item">
            <img v-cache="'/img/carousel/slide1.jpg'" alt="">
        </div>
        <div class="carousel__item">
            <img v-cache="'/img/carousel/slide2.jpg'" alt="">
        </div>
        <div class="carousel__item">
            <img v-cache="'/img/carousel/slide3.jpg'" alt="">
        </div>
    </vue-owl-carousel>
</template>

<script>
  import VueOwlCarousel from 'vue-owl-carousel'

  export default {
    name: 'Carousel',
    components: { VueOwlCarousel },
    mounted () {
      $(document).ready(() => {
        $('#main-carousel .owl-prev').html(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48pt" height="48pt" viewBox="0 0 48 48" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(12.941176%,58.823529%,95.294118%);fill-opacity:1;" d="M 30.898438 43 L 34 39.898438 L 18.101563 24 L 34 8.101563 L 30.898438 5 L 12 24 Z "/>
</g>
</svg>
`)
        $('#main-carousel .owl-next').html(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48pt" height="48pt" viewBox="0 0 48 48" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(12.941176%,58.823529%,95.294118%);fill-opacity:1;" d="M 17.101563 5 L 14 8.101563 L 29.898438 24 L 14 39.898438 L 17.101563 43 L 36 24 Z "/>
</g>
</svg>
`)

        $('#main-carousel .owl-prev').css('transform', 'scale(0.2)')
        $('#main-carousel .owl-next').css('transform', 'scale(0.2)')
        $('#main-carousel .owl-nav').css('left', '-23%')
        $('#main-carousel .owl-nav').css('top', '18%')
        if ($('header').width() > 1200) {
          $('#main-carousel .owl-nav').css('left', '-6%')
          $('#main-carousel .owl-nav').css('top', '35%')
        }
        $(window).resize(function () {

          if ($('header').width() > 1200) {
            $('#main-carousel .owl-nav').css('left', '-6%')
            $('#main-carousel .owl-nav').css('top', '35%')
          }

        })
      })
    }
  }
</script>

<style lang="scss" scoped>

    .carousel {
        &__item {
            display: flex;
            padding: 0px;
            background-color: #0A263E;
            width: 100%;
            height: 30vh;
        }
    }

    @media (min-width: 1200px) {
        .carousel {
            &__item {
                height: 55vh;
            }
        }
    }
</style>
