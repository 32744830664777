<template>
    <!-- <div class="newsletter" v-waypoint="{ active: true, callback: initilizeRallax}"> -->
    <div class="newsletter" :id="id" data-type="background" data-speed="2">
        <div class="newsletter__body">
            <h1 class="newsletter__title">Stay in the loop</h1>
            <p class="newsletter__subtitle">WE DON’T WANT YOU TO MISS ANYTHING</p>
        </div>
        <form
                class="newsletter__form"
                method="post"
                name="newsletter-form"
                data-netlify="true"
                netlify-honeypot="bot-field"
                @submit.prevent="handleSubmit"
        >
            <p class="hidden">
                <label>Don’t fill this out if you're human: <input name="bot-field"/></label>
            </p>
            <div>
                <i class="fas fa-envelope-open-text"></i>
                <p>join our weekly news!</p>
            </div>
            <input v-model="from.email" name="email" type="email" required placeholder="Enter Your Email Address...."/>
            <button type="submit">Submit Now</button>
        </form>
        <div class="newsletter__image">
            <!-- <img class="newsletter-rellax" src="/img/rome-4732390_1920_edited.jpg" alt /> -->
        </div>

        <sweet-modal
                ref="news_subs__modal"
                @close="()=> from.email = ''"
                icon="success"
        >
            <div class="popup">
                <h1 class="popup_title">Thank you for subscribe into our newsletter</h1>
                <p class="popup_text">We well get you soon..!</p>
            </div>
        </sweet-modal>
    </div>
</template>

<style lang="scss" scoped>
    .hidden {
        display: none;
    }

    .popup {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &_title {
            font-size: 1.1rem;
        }

        &_text {
            font-size: 1rem;
        }
    }

    .newsletter {
        position: relative;
        background-image: url("/img/loop.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        height: 60vh;
        width: 100%;

        &__title {
            font-size: 3rem;
            text-transform: uppercase;
        }

        &__body {
            display: flex;
            flex-direction: column;
            width: 100%;
            color: #fff;
            text-align: center;
            margin-top: 0;
        }

        &__form {
            padding: 1.5rem 1.1rem;
            text-align: center;
            background-color: #da9100;
            border-radius: 10px;
            color: #ffffff;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4em;

            display: flex;
            flex-direction: column;
            align-items: center;

            div {
                display: flex;
            }

            svg {
                font-size: 3rem;
            }

            p {
                text-align: start;
                font-size: 1.5em;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0px;
                margin-left: 1em;
                margin-top: 0px;
                width: 12rem;
            }

            input {
                width: 90%;
                font-size: 1.01em;
                padding: 1rem;
                margin-top: 1em;
                margin-bottom: -2em;
                border-color: white;
                border-radius: 5px 0px 0px 5px;
            }

            input:focus {
                outline: none;
            }

            button {
                font-family: Roboto;
                font-size: 16px;
                margin-top: 3rem;
                padding: 1em 1.9em;
                border-color: black;
                background-color: #000000;
                color: #ffffff;
                border-radius: 5px;
                cursor: pointer;
            }

            button:hover{
                background-color: #131313;
                border-color: #131313;
            }
        }

        @media (min-width: 1024px) {
            .newsletter {
                background-size: 130% 130%;

                &__body {
                    padding-top: 2rem;
                }

                &__form {
                    flex-direction: row;
                    margin-top: 5.5rem;

                    div {
                        margin-top: 1rem;
                        margin-right: 1rem;
                    }

                    input {
                        width: 50%;
                        margin-top: -1.5rem;
                    }

                    button {
                        border-radius: 0px 5px 5px 0px;
                        margin-top: .6rem;
                        height: 55px;
                    }
                }
            }
        }
    }
</style>

<script>
    import {SweetModal} from "sweet-modal-vue"

    export default {
        name: "NewsletterSubscriptionForm",
        components: {SweetModal},
        data() {
            return {
                id: null,
                from: {
                    email: ''
                }
            };
        },
        mounted() {
            this.id = this._uid;
            let that = this;

            function parallax() {
                var $slider = document.getElementById(that.id);
                var yPos = window.pageYOffset - 50 / $slider.dataset.speed;
                yPos = -yPos;
                var coords = "0% " + yPos + "px";
                $slider.style.backgroundPosition = coords;
            }
        },
        methods: {
            encode(data) {
                return Object.keys(data)
                    .map(
                        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                    )
                    .join("&");
            },
            handleSubmit(e) {
                let that = this;
                fetch("/", {
                    method: "POST",
                    headers: {"Content-Type": "application/x-www-form-urlencoded"},
                    body: this.encode({
                        "form-name": "newsletter-form",
                        ...that.from
                    })
                }).then(() => that.$refs.news_subs__modal.open())

              setTimeout(() => that.$refs.news_subs__modal.open(), 500)

            },
        }
    };
</script>

