<template>
    <div :class="`card card__${direction}`">
        <div class="card__imageContainer" v-html="thumb" v-if="/<\/?[a-z][\s\S]*>/i.test(thumb)"></div>
        <div class="card__imageContainer" v-else>
            <img v-cache="thumb" alt/>
        </div>
        <div class="card__body">
            <a class="card__title" href="#">
                <h2>{{ title }}</h2>
            </a>
            <p class="card__text">{{ card_Text }}</p>
            <a class="showless" v-if="(this.cardText.length > 580) && !showLess" href="#"
               @click.prevent="showLess = true">read more..</a>
            <a class="showless" v-if="showLess" href="#" @click.prevent="showLess = false">show less</a>
            <img class="background-deco" src="/img/storke.svg" alt/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Card",
        props: {
            direction: {
                type: String,
                default: "left"
            },
            thumb: {
                type: String,
                default: `<img src="/img/dummy-card-image.jpg" alt="card image">`
            },
            title: {
                type: String,
                default:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, velit!"
            },
            cardText: {
                type: String,
                default:
                    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi culpa doloribus iste magni pariatur qui\n" +
                    "        sequi. Assumenda consequuntur dignissimos dolor doloremque, earum error fugiat fugit id minima numquam\n" +
                    "        officia praesentium repellat sed sequi tenetur ut."
            }
        },
        data() {
            return {
                showLess: false
            }
        },
        computed: {
            card_Text() {
                return (this.cardText.length > 580) && !this.showLess ? `${this.cardText.substring(0, 580)}...` : this.cardText;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/variables";


    .card {
        display: flex;
        flex-direction: column;
        align-items: start;

        &__imageContainer {
            position: relative;
            width: 100%;
            height: auto;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: start;
            padding: 1rem 0rem;
            position: relative;
            width: 100%;

            .background-deco {
                display: none;
            }
        }

        &__title {
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: 100;
            color: #da9100;
            text-align: left;
        }

        &__text {
            font-size: 1rem;
            text-align: justify;
            margin-top: 0px;
        }

        .showless {
            color: $primary;
        }
    }

    /*'sm': '640px',*/
    @media (min-width: 640px) {
    }

    /*'md': '768px',*/
    @media (min-width: 768px) {
    }

    /*'lg': '1024px',*/
    @media (min-width: 1024px) {
        .card__left {
            flex-direction: row !important;

            .card__body {
                margin: 0rem 0rem 0rem 2rem !important;
            }
        }

        .card__right {
            flex-direction: row-reverse !important;

            .card__body {
                margin: 0rem 2rem 0rem 0rem !important;
            }
        }

        .card {
            flex-direction: row;
            justify-content: space-between;
            padding: 2rem 0rem;

            &__imageContainer {
                iframe {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 560px;
                    height: auto;
                }
            }

            &__body {
                margin-left: 2rem;
                align-self: center;
                padding: 1rem 0.75rem;

                .background-deco {
                    display: inherit;
                    position: absolute;
                    opacity: 0.17;
                    z-index: 0;
                    bottom: 0;
                    left: -50px;
                    transform: scale(0.5) rotateZ(-20deg);
                }
            }

            &__title {
                font-size: 1.5rem;
            }
        }
    }

    /*'xl': '1280px',*/
    @media (min-width: 1280px) {
    }
</style>
