<template>
    <div class="g-ytsubscribe" data-channelid="UCFUC1tsItNJe7gPxcgeydKw" data-layout="full" data-count="default"></div>
</template>

<script>
    export default {
        name: "SubscribeButton",
        created() {
            const script = document.createElement('script');
            script.src= 'https://apis.google.com/js/platform.js';
            document.body.append(script)
        }
    }
</script>

<style scoped>

</style>
