<template>
    <div class="home">

        <section id="home-main">
            <carousel/>
            <!-- <carousel-decorator /> -->
            <section id="netflix-latest-videos">
                <latest-videos-netflix/>
            </section>
        </section>
        <section id="latest-videos">
            <h1 class="title" v-if="$store.getters.LATEST_VIDEOS.length > 0">Recent Videos</h1>
            <p class="subtitle" v-if="$store.getters.LATEST_VIDEOS.length > 0">Becoming Who God Created You</p>
            <latest-videos/>
        </section>
        <section id="soj-summary">
            <!-- <card :thumb="soj" title="Sound Of Jesus"/> -->
            <sound-of-jesus
                    video-id="ubDcmIUMcLo"
                    :desc="$store.state.about.filter( sec => sec.id === 'soj' )[0].description"
            ></sound-of-jesus>
        </section>
        <section id="author-summary">
            <card
                    :thumb="$store.state.about.filter( sec => sec.id === 'fkr' )[0].file"
                    title="Fr. Kalana Inshara Peiris"
                    direction="right"
                    :card-text="$store.state.about.filter( sec => sec.id === 'fkr' )[0].text"
            />
        </section>
        <section id="subscribe-newsletter">
            <newsletter-subscription-form/>
        </section>
        <section id="youtube-playlist">
            <youtube-playlist/>
        </section>
        <section id="youtube-statistic">
            <youtube-statistic/>
        </section>
        <section id="recent-events">
            <h1 class="title">Recent Events</h1>
            <p class="subtitle">Becoming Who God Created You</p>
            <card-carousel :events="$store.state.events.filter(v => !v.deleted_at)"/>
        </section>
    </div>
</template>

<script>
    import Card from "../components/comm/Card";
    import YoutubePlaylist from "../components/home/YoutubePlaylist";
    import CardCarousel from "../components/comm/CardCarousel";
    import NewsletterSubscriptionForm from "../components/comm/NewsletterSubscriptionForm";
    import Carousel from "../components/home/Carousel";
    import YoutubeStatistic from "../components/home/YoutubeStatistic";
    import LatestVideos from "../components/home/LatestVideos";
    import AOS from "aos";
    import "aos/dist/aos.css";
    import SoundOfJesus from "../components/about/SoundOfJesus";
    import CarouselDecorator from "../components/home/CarouselDecorator";

    import LatestVideosNetflix from "../components/home/LatestVideosNetflix";
    export default {
        name: "home",
        components: {
            CarouselDecorator,
            SoundOfJesus,
            LatestVideos,
            YoutubeStatistic,
            Carousel,
            NewsletterSubscriptionForm,
            CardCarousel,
            YoutubePlaylist,
            Card,
            LatestVideosNetflix
        },
        data() {
            return {
                soj: `<div class='embed-container'>
                         <iframe src="https://www.youtube.com/embed/ubDcmIUMcLo" frameborder='0' allowfullscreen></iframe>
                      </div>`,
                kalana: {
                    about:
                        "Fr. Kalana Inshara Peiris was born on the 12th August 1978 in Kalutara, Sri Lanka to Mrs. Kanthi \n" +
                        "Peiris and Late Dr. Charles Peiris as their second child and is the brother of Inshira (sister), \n" +
                        "Inshika (sister) and Inshaka (brother). He had his primary and secondary education at St. Peter’s \n" +
                        "College Colombo 04 before entering to St. Aloysius Seminary Borella in 1995, after humbly \n" +
                        "accepting the call of God to be a shepherd to his flock. Thereafter to the National Seminary \n" +
                        "Kandy, to complete his formation to be a priest and was ordained on 12th of January 2008."
                }
            };
        }
    };
</script>

<style lang="scss">
    #netflix-latest-videos {
        margin: 0px;
        padding: 0px;
        display: none;
    }

    #youtube-playlist {
        margin-top: 15rem;
    }

    .img_container {
        position: relative;

        h1 {
            position: absolute;
            left: 0;
            top: 50px;
            padding: 1rem 2rem;
            background-color: #0e76a8;
            color: #ffffff;
        }

        img {
            width: 100%;
            height: 50%;
        }
    }

    section {
        padding: 1rem;
    }

    .title {
        color: #da9100;
        width: 100%;
        text-align: center;
        position: relative;
        font-size: 2rem;
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .subtitle {
        color: #347d7a;
        margin-bottom: 3rem;
        margin-top: 0px;
        text-align: center;
        font-family: "Barlow", sans-serif;
    }

    #latest-videos {
        .latestVideos {
            .video {
                .thumbnail {
                    iframe {
                        width: 100%;
                        height: 205px;
                    }
                }
            }
        }
    }

    #subscribe-newsletter{
        margin-bottom: 10em;
        padding: 0px;
    }

    #youtube-statistic {
        padding: 0px;
        margin: 0px 0px 4em;
    }

    #home-main {
        margin: auto 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .owl-nav {
            position: absolute;
            padding: 0 5rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            top: 45%;
            left: -5%;

            .owl-next,
            .owl-prev {
                background-color: transparent;

                svg {
                    transform: scale(4);
                }
            }
        }
    }

    #recent-events {
        padding-top: 1rem;
        padding-bottom: 3rem;

        .title {
            width: 100%;
            text-align: center;
            position: relative;
            font-size: 2rem;
            margin-bottom: 10px;
            text-transform: uppercase;
        }

        .subtitle {
            color: #347d7a;
            margin-bottom: 3rem;
            margin-top: 0px;
            text-align: center;
            font-family: "Barlow", sans-serif;
        }

        .owl-nav {
            position: absolute;
            padding: 0 1.5rem;
            width: 99vw;
            display: flex;
            justify-content: space-between;
            margin: 0;
            top: 28%;
            left: -3.8rem;

            div {
                background-color: transparent;
            }

            svg {
                transform: scale(3.5);
                color: black;
            }
        }

        .owl-dots {
            margin-top: 1rem;
        }
    }


    #soj-summary {
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: #f0f0f0;
    }


    #soj-summary,
    #author-summary,
    #subscribe-newsletter,
    #youtube-statistic {
        margin-top: 4rem;
    }

    .sweet-title {
        color: #da9100;
    }

    .sweet-modal .sweet-box-actions .sweet-action-close:hover {
        background-color: #da9100;
    }

    #home-yputube-playlist-model {
        .sweet-content {
            overflow-x: hidden;
        }
    }

    @media (min-width: 1024px) {
        #netflix-latest-videos {
            display: unset;
        }

        #latest-videos {
            display: none;
        }

        #recent-events {
            padding-left: 2.7rem;

            .owl-nav {
                width: 98.2vw;
            }
        }

        section {
            padding: 1rem 5rem;
        }

        #youtube-playlist {
            margin-top: 7rem;
        }
    }
</style>
