import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from 'axios'
import { vuexfireMutations } from 'vuexfire'
import latest from './latest'
import events from './events'
import { firestoreAction } from 'vuexfire'
import db from '@/helpers/db'
import firebase from '@/helpers/firebase'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'my-app',
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    channel: undefined,
    playlists: [],
    playlistItems: [],
    latestVideos: [],
    audios: [],
    about: [],
    contact: [],
    events: [],
    currentlyPlaying: null,
    recentEvents: []
  },
  getters: {
    CHANNEL: state => {
      return state.channel[0]
    },
    LATEST_VIDEOS: state => {
      return state.latestVideos
    },
    CURRENTLY_PLAYING: state => {
      return state.currentlyPlaying
    },
    RECENT_EVENTS: state => {
      return state.recentEvents
    }
  },
  mutations: {
    ...vuexfireMutations,
    SET_CHANNEL: (state, payload) => {
      state.channel = payload
    },
    SET_LATEST_VIDEOS: (state, payload) => {
      state.latestVideos = payload
    },
    SET_CURRENTLY_PLAYING: (state, payload) => {
      state.currentlyPlaying = payload
    },
    SET_RECENT_EVENTS: (state, payload) => {
      state.recentEvents = payload
    }
  },
  actions: {
    init: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef('playlists', db.collection('playlists'))
      bindFirestoreRef('latestVideos', db.collection('latestVideos'))
      bindFirestoreRef('playlistItems', db.collection('playlistItems'))
      bindFirestoreRef('about', db.collection('about'))
      bindFirestoreRef('contact', db.collection('contact'))
      bindFirestoreRef('events', db.collection('events'))
      bindFirestoreRef('audios', db.collection('audios'))
      bindFirestoreRef('channel', db.collection('channel'))
    }),
    async persistDatabase (context) {
       return db.collection('sequence')
        .doc('Ueyx8LhTkLQcSoZV4DzJ')
        .get()
        .then(doc => {
          if (doc.exists) {
            const last_persist_time = new Date(doc.data().last_persist.seconds * 1000)
            const current_date_time = new Date()
            if (Math.abs(last_persist_time.getTime() - current_date_time.getTime()) / 36e5 >= 1.0) {
                // code here to persist database
                console.log('is Persist')
                fetch('https://us-central1-soj-backend.cloudfunctions.net/insertLatestVideos', { mode: 'no-cors' })
                  .then(result => {
                      db.collection('sequence')
                        .doc('Ueyx8LhTkLQcSoZV4DzJ')
                        .update({
                          last_persist: firebase.firestore.FieldValue.serverTimestamp()
                        })
                  })
            }
          }
        })
    },
    GET_CHANNEL: ({ commit }) => {

      const URL = 'https://www.googleapis.com/youtube/v3/channels'

      /*return new Promise(((resolve, reject) => {
          axios.get(`${URL}?part=statistics%2C%20snippet&id=${CHANNEL_ID}&key=${API_KEY}`)
              .then(resp => resp.data)
              .then(resp => {
                  let data = Object.assign(resp.items[0].statistics, resp.items[0].snippet)
                  commit("SET_CHANNEL", data)
                  resolve()
              }).catch(error => console.error(error))
      }))*/

      return new Promise((resolve, reject) => {
        let data = {
          'viewCount': '605075',
          'commentCount': '0',
          'subscriberCount': '3450',
          'hiddenSubscriberCount': false,
          'videoCount': '629',
          'title': 'Sound of Jesus',
          'description': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut beatae, eius expedita incidunt mollitia numquam porro possimus tempora, totam ullam voluptas. Fugiat illo impedit laborum porro quidem, quo tempore. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aut beatae, eius expedita incidunt mollitia numquam porro possimus tempora, totam ullam voluptas. Fugiat illo impedit laborum porro quidem, quo tempore.',
          'customUrl': 'soundofjesus',
          'publishedAt': '2016-11-19T19:21:35.000Z',
          'thumbnails': {
            'default': {
              'url': 'https://yt3.ggpht.com/a/AATXAJyaBxHHf6ieIHZrGRVLZVCu4uXTN2eJEYCodg=s88-c-k-c0xffffffff-no-rj-mo',
              'width': 88,
              'height': 88
            },
            'medium': {
              'url': 'https://yt3.ggpht.com/a/AATXAJyaBxHHf6ieIHZrGRVLZVCu4uXTN2eJEYCodg=s240-c-k-c0xffffffff-no-rj-mo',
              'width': 240,
              'height': 240
            },
            'high': {
              'url': 'https://yt3.ggpht.com/a/AATXAJyaBxHHf6ieIHZrGRVLZVCu4uXTN2eJEYCodg=s800-c-k-c0xffffffff-no-rj-mo',
              'width': 800,
              'height': 800
            }
          },
          'localized': {
            'title': 'Sound of Jesus',
            'description': ''
          }
        }
        commit('SET_CHANNEL', data)
        resolve()
      })
    },
    GET_LATEST_VIDEOS: ({ commit }) => {
      return new Promise((resolve, reject) => {
        latest.then(resp => {
          if (process.env.NODE_ENV === 'production') {
            commit('SET_LATEST_VIDEOS', resp.items)
          } else {
            commit('SET_LATEST_VIDEOS', resp)
          }
          resolve()
        })
      })
    },
    NOW_PLAYING: (injectee, payload) => {
      let currentlyPlaying = injectee.state.currentlyPlaying
      if (currentlyPlaying !== null && currentlyPlaying !== payload) {
        injectee.dispatch('CLOSE_CURRENTLY_FLOATED_VIDEO')
          .then(() => {
            currentlyPlaying.pauseVideo()
          })
      }
      injectee.commit('SET_CURRENTLY_PLAYING', payload)
    },
    CLOSE_CURRENTLY_FLOATED_VIDEO: injectee => {
      return new Promise((resolve, reject) => {
        let player = injectee.state.currentlyPlaying

        if (player !== null) {
          player.getIframe()
            .then(iframe => {
              let parent = iframe.parentNode
              if (parent !== null) {
                parent.classList.remove('float-player')
                parent.querySelector('.close-float-video').classList.remove('close-float-video--active')
              }
              resolve()
            })
        }

      })
    },
    DO_FLOAT_VIDEO: injectee => {
      let player = injectee.state.currentlyPlaying
      if (player !== null) {
        player.getPlayerState()
          .then(state => {
            if (state === 1) {
              player.getIframe()
                .then(iframe => {
                  let parent = iframe.parentNode
                  parent.classList.add('float-player')
                  parent.querySelector('.close-float-video').classList.add('close-float-video--active')
                })
            }
          })
      }
    },
    GET_RECENT_EVENTS: injectee => {

      return new Promise((resolve, reject) => {
        events.then(resp => {
          // if (process.env.NODE_ENV === 'production') {
          //     injectee.commit("SET_RECENT_EVENTS", resp)
          // }else{
          // }
          injectee.commit('SET_RECENT_EVENTS', resp)
          resolve(resp)
        })
      })
    }
  },
  plugins: [vuexPersist.plugin]

})
