<template>
    <div class="card">
        <div class="thumbnail embed-container">
            <a class="close-float-video"
               @click="() => $store.dispatch('CLOSE_CURRENTLY_FLOATED_VIDEO').then(() => $store.state.currentlyPlaying.pauseVideo())">
                <i class="far fa-times-circle"></i>
            </a>
            <div class="container" :style="`background-image: url(${thumbnail()});`" v-if="isLoading" @click="playVideo">
                <div class="wrapper">
                    <div class="tri"></div>
                </div>
            </div>
            <youtube
                    v-else
                    :video-id="videoId"
                    :ref="videoId"
                    @ready="readyPlayer"
                    @playing="playing"
                    @paused="paused"
            ></youtube>
        </div>
        <div class="card__body">
            <h2 class="card__title">{{ title }}</h2>
            <h4 class="card__date">
                <i class="far fa-clock"></i> {{ publishedAt | moment('DD MMMM YYYY')}}
            </h4>
            <p class="card__description">
                {{ videoDescription }}
            </p>
            <div class="card__social">
                <span>Share :</span>
                <a href="#"><i class="fab fa-facebook"></i></a>
                <a href="#"><i class="fab fa-instagram-square"></i></a>
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-linkedin"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Card",
        props: {
            videoId: String,
            title: String,
            publishedAt: String,
            videoDescription: String,
            thumbnails: Object
        },
      mounted() {
          console.log('sasadasd', this.videoId)
      },
        data() {
            return {
                isLoading: true,
            }
        },
        methods: {
            thumbnail(){
              if (!this.thumbnails) return;

              if(this.thumbnails.standard){
                  return this.thumbnails.standard.url;
              }else if(this.thumbnails.high){
                  return this.thumbnails.high.url;
              }else if(this.thumbnails.medium){
                  return this.thumbnails.medium.url;
              }else{
                  return this.thumbnails.default.url;
              }
            },
            playVideo() {
                this.isLoading = false;
                this.$emit("playing")
            },
            readyPlayer() {
                this.$refs[this.videoId].player.playVideo()
            },
            playing(){
                this.$store.dispatch('NOW_PLAYING', this.$refs[this.videoId].player);
                this.$emit("playing")
            },
            paused(){
                this.$emit("paused")
            }
        }
    }
</script>

<style lang="scss" scoped>
    @mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
        overflow: hidden;
        position: relative;
        line-height: $lineHeight;
        max-height: $lineHeight * $lineCount;
        text-align: justify;
        margin-right: -1em;
        padding-right: 1em;
        &:before {
            content: '...';
            position: absolute;
            right: 0;
            left: -10px;
            bottom: 0;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 1em;
            height: 1em;
            margin-top: 0.2em;
            background: $bgColor;
        }
    }
    .card {
        box-shadow: 2px 2px 20px -7px rgba(0, 0, 0, 0.75);
        height: 100%;
        .thumbnail {
            width: 100%;
            position: relative;
            cursor: default;

            .close-float-video {
                padding: .4rem;
                width: .5rem;
                margin-left: auto;
                margin-right: 5px;
                font-size: 1rem;
                display: none;

                svg {
                    cursor: pointer;
                }
            }

            .close-float-video--active {
                display: block;
            }

            .close-float-video:hover {
                color: #da9100;
            }
        }
        .container {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: calc(100% - 0px);
            background-repeat: no-repeat;
            background-position: center;
            transform-style: preserve-3d;
            cursor: pointer;
        }

        .wrapper {
            background: rgba(0, 0, 0, 0.55);
            width: 50px;
            height: 24px;
            border-radius: 5px;
            padding-top: 10px;
            position: relative;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
        }

        .container:hover .wrapper {
            background: #cd201f;
        }

        .tri {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7px 0 7px 14px;
            border-color: transparent transparent transparent #ffffff;
            margin: 0 auto;
        }

        &__body {
            padding: 1rem;
            display: none;
        }

        &__image {
            width: 100%;
            height: auto;
        }

        &__title {
            font-size: 1.2rem;
            margin-bottom: 0px;
            color: #da9100;
            @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: transparent);
        }

        &__date {
            text-align: right;
            font-size: .8rem;
            color: #BDBDBD;
            margin-top: 6px;
        }

        &__description {
            text-align: justify;
            font-family: 'Barlow', sans-serif;
        }

        &__footer {
            display: flex;
            justify-content: space-between;
        }

        &__social {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            color: #BDBDBD;
            font-size: .8rem;

            a, span {
                text-decoration: none;
                margin: .2rem;
            }

            a {
                font-size: 1rem;
            }

            a:hover {
                color: #da9100 !important;
            }

            a:nth-child(1) {
                color: #3b5998;
            }

            a:nth-child(2) {
                color: #405DE6;
            }

            a:nth-child(3) {
                color: #38A1F3;
            }

            a:nth-child(4) {
                color: #0e76a8;
            }
        }
    }

    .card, card__body {
        position: relative;
        display: flex;
        flex-direction: column;
    }
</style>
