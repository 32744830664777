<template>
    <div class="listmod">
        <sweet-modal ref="list-mod" :title="title">
            <div class="listmod__card">
                <div class="listmod__thumb">
                    <!--                    <iframe-->
                    <!--                            :src="`http://www.youtube.com/embed/videoseries?list=${playlistId}`"-->
                    <!--                            frameborder="0"-->
                    <!--                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"-->
                    <!--                            allowfullscreen-->
                    <!--                    >-->
                    <!--                    </iframe>-->
                    <div class="container" :style="`background-image: url(${thumbnail()});`" v-if="isLoading"
                         @click="playVideo">
                        <div class="wrapper">
                            <div class="tri"></div>
                        </div>
                    </div>
<!--                    <youtube-->
<!--                            :video-id="playlistId"-->
<!--                            :ref="playlistId"-->
<!--                            @ready="readyPlayer"-->
<!--                            @playing="playing"-->
<!--                            @paused="paused"-->
<!--                    ></youtube>-->
                    <iframe
                            v-else
                            :src="`http://www.youtube.com/embed/videoseries?list=${playlistId}`"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                    >
                    </iframe>
                </div>
                <h4 class="listmod__date">
                    <i class="far fa-clock"></i> {{ publishedAt | moment('DD MMMM YYYY') }}
                </h4>
                <p class="listmod__description">{{ description }}</p>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
    import {SweetModal} from "sweet-modal-vue"

    export default {
        name: "PlaylistModel",
        components: {SweetModal},
        props: {
            title: {
                type: String,
                required: true
            },
            description: String,
            playlistId: {
                type: String,
                required: true
            },
            publishedAt: {
                type: String,
                required: true
            },
            thumbnails: {
                type: Object | String
            }
        },
        data() {
            return {
                isLoading: true
            }
        },
        methods: {
            open() {
                this.$refs['list-mod'].open()
            },
            readyPlayer() {
                this.$refs[this.playlistId].player.playVideo()
            },
            playVideo() {
                this.isLoading = false;
                this.$emit("playing")
            },
            playing() {
                this.$store.dispatch('NOW_PLAYING', this.$refs[this.playlistId].player);
                this.$emit("playing")
            },
            paused() {
                this.$emit("paused")
            },
            thumbnail() {
                if (!this.thumbnails) return;

                if (this.thumbnails.standard) {
                    return this.thumbnails.standard.url;
                } else if (this.thumbnails.high) {
                    return this.thumbnails.high.url;
                } else if (this.thumbnails.medium) {
                    return this.thumbnails.medium.url;
                } else {
                    return this.thumbnails.default.url;
                }
            }
        }
    }
</script>

<style lang="scss">
    .listmod {
        &__card {
            display: flex;
            flex-direction: column;
        }

        &__thumb {
            position: relative;
            overflow-x: hidden;
            width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .container {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: calc(100% - 0px);
                background-repeat: no-repeat;
                background-position: center;
                transform-style: preserve-3d;
                cursor: pointer;
            }

            .wrapper {
                background: rgba(0, 0, 0, 0.55);
                width: 50px;
                height: 24px;
                border-radius: 5px;
                padding-top: 10px;
                position: relative;
                margin: 0 auto;
                top: 50%;
                transform: translateY(-50%);
            }

            .container:hover .wrapper {
                background: #cd201f;
            }

            .tri {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 14px;
                border-color: transparent transparent transparent #ffffff;
                margin: 0 auto;
            }
        }

        &__thumb::after {
            padding-top: 56.25%;
            display: block;
            content: '';
        }

        &__date {
            text-align: right;
            font-size: .8rem;
            color: #BDBDBD;
            margin-top: 10px;
        }

        &__description {
            font-family: 'Barlow', sans-serif;
        }
    }
</style>
